import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-renderingZone': {
      width: '100%',
      '& .MuiDataGrid-row': {
        '&:nth-child(2n+1)': {
          backgroundColor: theme.palette.table.odd
        }
      }
    },
    border: 'unset',
    '& .MuiDataGrid-columnHeaderWrapper': {
      borderBottom: '2px solid ' + theme.palette.secondary.main,
      color: theme.palette.secondary.main
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'unset'
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'unset'
    }
  },
  cell: {
    fontSize: theme.palette.table.cell.fontSize,
    color: theme.palette.table.cell.color
  }
}));

const EditableTable = ({ rows, columns, pageSize, ...rest }) => {
  const classes = useStyles();
  return (
    <DataGrid
      classes={{
        root: classes.root,
        cell: classes.cell
      }}
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[5]}
      {...rest}
    />
  );
};

EditableTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  pageSize: PropTypes.number
};

EditableTable.defaultProps = {
  pageSize: 5
};

export default EditableTable;
