import React from 'react';
import PropTypes from 'prop-types';
import { getSize } from './index';
import { makeStyles, TextField, withStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 'unset !important'
  },
  fieldColors: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: ({ fieldColor }) => fieldColor
    }
  },
  fieldSizes: {
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: ({ fieldSize }) => fieldSize,
      paddingBottom: ({ fieldSize }) => fieldSize
    }
  }
}));

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputAdornment-root': {
      maxHeight: 'unset',
      padding: '1em',
      backgroundColor: theme.palette.common.lightGrey
    },
    '& .MuiSvgIcon-root': {
      width: '0.85em'
    },
    '& .MuiOutlinedInput-adornedEnd, .MuiOutlinedInput-adornedStart': {
      padding: 0
    },
    '& .MuiInputAdornment-positionEnd': {
      borderWidth: 1,
      borderLeft: '1px solid ' + theme.palette.common.grey,
      height: '3.5rem',
      margin: 0
    },
    '& .MuiInputAdornment-positionStart': {
      borderWidth: 1,
      borderRight: '1px solid ' + theme.palette.common.grey,
      height: '3.5rem',
      margin: 0
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
      paddingLeft: '1em'
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      paddingRight: '1em'
    }
  }
}))(TextField);

const FormTextField = ({
  color,
  disabled,
  readOnly,
  staticControl,
  text,
  dimension,
  ...rest
}) => {
  const fieldSize = getSize(dimension);
  const classes = useStyles({ color, fieldSize });

  if (staticControl) {
    return <p className='form-control-static'>{text}</p>;
  }

  return (
    <StyledTextField
      disabled={disabled}
      variant='outlined'
      classes={{ root: clsx(classes.fieldColors, classes.fieldSizes) }}
      InputProps={{
        readOnly: readOnly
      }}
      {...rest}
    />
  );
};

FormTextField.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  dimension: PropTypes.oneOf(['small', 'medium', 'large'])
};

FormTextField.defaultProps = {
  size: 'small'
};

export default FormTextField;
