import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
  TextField,
  useTheme
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  searchButtonContainer: {
    display: 'flex',
    borderStyle: 'solid solid solid hidden',
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    borderColor: theme.palette.textField.border,
    borderWidth: '0.4px',
    backgroundColor: theme.palette.background.default
  }
}));

const SearchBar = ({
  value,
  onChange,
  onSearchButtonClick,
  onKeyPress,
  placeholder,
  helperText,
  disabled,
  width,
  height,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box p='1' width='100%' height='auto' display='flex' flexDirection='row'>
      <TextField
        variant='outlined'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        helperText={helperText}
        disabled={disabled}
        fullWidth
        InputProps={{
          style: {
            width: width,
            height: height,
            borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
          }
        }}
        {...rest}
      />
      <Box className={classes.searchButtonContainer}>
        <Button onClick={onSearchButtonClick}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Box>
    </Box>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearchButtonClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string
};

SearchBar.defaultProps = {
  value: '',
  onChange: () => null,
  onSearchButtonClick: () => null,
  onKeyPress: () => null,
  placeholder: 'Search...',
  helperText: '',
  disabled: false,
  width: '100%',
  height: '48px'
};

export default SearchBar;
