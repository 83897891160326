import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary
  },
  subtitle: {
    color: theme.palette.text.secondary
  }
}));

const TableHeader = ({ title, subtitle, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Box
      p={1}
      width='100%'
      height='auto'
      display='flex'
      alignItems='center'
      {...rest}
    >
      <Box flexGrow='3'>
        <Typography className={classes.title} variant='h3'>
          {title}
        </Typography>
        <Typography className={classes.subtitle} variant='h6'>
          {subtitle}
        </Typography>
      </Box>
      <Box flexGrow='1'>{children}</Box>
    </Box>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.any
};

TableHeader.defaultProps = {
  title: '',
  subtitle: '',
  children: null
};

export default TableHeader;
