import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  makeStyles,
  Toolbar,
  Typography,
  Button,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = '15.5rem';
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      paddingTop: '3.75rem'
    }
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('md')]: {
      paddingTop: '3.75rem'
    }
  },
  drawerContainer: {
    overflowX: 'hidden'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '3.5rem'
  },
  title: {
    opacity: 0.54,
    color: theme.palette.common.white,
    padding: 0,
    margin: theme.spacing(2),
    fontFamily: theme.typography.h2.fontFamily,
    flexGrow: 1
  },
  drawerToolbar: {
    padding: 0,
    minHeight: '3.375rem'
  },
  menuIcon: {
    backgroundColor: theme.palette.secondary.dark,
    margin: 0,
    height: '3.125rem',
    color: theme.palette.secondary.contrastText,
    minWidth: '3.125rem',
    padding: theme.spacing(2),
    borderRadius: '0.125rem'
  }
}));

const SideBar = ({ title, open, onMenuClick, children, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClick = () => {
    if (onMenuClick) {
      onMenuClick();
    }
  };

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      variant={matches ? 'temporary' : 'permanent'}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open={open}
      onClose={handleMenuClick}
    >
      <Toolbar className={classes.drawerToolbar}>
        {open && (
          <Typography className={classes.title} variant='h6'>
            {title}
          </Typography>
        )}
        <Button
          onClick={handleMenuClick}
          className={classes.menuIcon}
          aria-label='menu'
        >
          <MenuIcon />
        </Button>
      </Toolbar>
      <div className={classes.drawerContainer}>
        <div {...rest}>{children}</div>
      </div>
    </Drawer>
  );
};

SideBar.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onMenuClick: PropTypes.func
};

SideBar.defaultProps = {
  open: false
};

export default SideBar;
