const typography = {
  fontFamily: ["NeoSansRegular", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
  h1: {
    fontFamily: ["NeoSansMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '2.18rem',
    letterSpacing: '-0.3px'
  },
  h2: {
    fontSize: '1.875rem',
    letterSpacing: '-0.3px'
  },
  h3: {
    fontFamily: ["NeoSansMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '1.375rem',
    letterSpacing: '-0.06px'
  },
  h4: {
    fontSize: '1.125rem',
    letterSpacing: '-0.06px'
  },
  h5: {
    fontFamily: ["NeoSansMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '1rem',
    letterSpacing: '-0.05px'
  },
  h6: {
    fontFamily: ["NeoSansMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '0.875rem',
    letterSpacing: '-0.05px'
  },
  overline: {
    fontFamily: ["NeoSansMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
  },
  subtitle2: {
    fontFamily: ["NeoSansMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
  },
  button: {
    fontFamily: ["NeoSansMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
  }
};

export default typography;
