
export const getSize = (size) => {
  switch (size) {
    case 'small':
      console.log('small');
      return '0.7em';
    case 'medium':
      console.log('medium');
      return '0.90em';
    case 'large':
      console.log('large');
      return '1.1em';
  }
};

export default { getSize };
