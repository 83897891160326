import React from 'react';
import { List, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginTop: 0,
    paddingTop: 0
  }
}));

const SidebarMenuList = ({ children }) => {
  const classes = useStyles();
  return (
    <List
      component='nav'
      aria-labelledby='nested-list-subheader'
      className={classes.root}
    >
      {children}
    </List>
  );
};

export default SidebarMenuList;
