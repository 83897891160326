import React from 'react';
import PropTypes from 'prop-types';
import { Box, DialogTitle, Typography } from '@material-ui/core';
import { useVariantDetails } from './utils';

const AlertDialogModalHeader = ({ title, variant }) => {
  const variantDetails = useVariantDetails(variant);
  return (
    <DialogTitle id='alert-dialog-title'>
      <Box display='flex'>
        <Box pr={1}>{variantDetails.icon}</Box>
        <Typography
          style={{ color: variantDetails.color }}
          variant='h3'
          gutterBottom
        >
          {title}
        </Typography>
      </Box>
    </DialogTitle>
  );
};

AlertDialogModalHeader.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning'])
};

AlertDialogModalHeader.defaultProps = {
  title: '',
  variant: 'info'
};

export default AlertDialogModalHeader;
