import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  media: {
    height: '50vh',
    border: 'unset'
  }
}));

const DialogModalContentIframe = ({ src }) => {
  const classes = useStyles();
  return <CardMedia className={classes.media} component='iframe' src={src} />;
};

DialogModalContentIframe.propTypes = {
  src: PropTypes.string.isRequired
};

DialogModalContentIframe.defaultProps = {
  src: ''
};

export default DialogModalContentIframe;
