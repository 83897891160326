import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import DialogModalHeader from './Dialog/DialogModalHeader';
import DialogModalContentIframe from './Dialog/DialogModalContentIframe';

const DialogModal = ({ onClose, open, title, iframe, children, ...rest }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      {...rest}
    >
      {title && <DialogModalHeader title={title} />}
      {iframe && <DialogModalContentIframe src={iframe} />}
      {children}
    </Dialog>
  );
};

DialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  iframe: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func
};

DialogModal.defaultProps = {
  open: false
};

export default DialogModal;
