import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import { getColor } from './index';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  chipColorFilled: {
    backgroundColor: ({ chipColor }) => chipColor,
    color: theme.palette.common.white
  },
  chipColorOutlined: {
    backgroundColor: theme.palette.common.white,
    color: ({ chipColor }) => chipColor,
    borderColor: ({ chipColor }) => chipColor
  }
}));

const ChipLabel = ({ text, color, variant, ...rest }) => {
  const chipColor = getColor(color);

  const classes = useStyles({ chipColor });

  return (
    <Chip
      className={
        variant === 'outlined'
          ? classes.chipColorOutlined
          : classes.chipColorFilled
      }
      label={text}
      variant={variant}
      {...rest}
    />
  );
};

ChipLabel.propTypes = {
  color: PropTypes.oneOf([
    'default',
    'secondary',
    'success',
    'warning',
    'danger'
  ]),
  variant: PropTypes.oneOf(['default', 'outlined'])
};

ChipLabel.defaultProps = {
  color: 'default',
  variant: 'default'
};

export default ChipLabel;
