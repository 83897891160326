import React from 'react';
import PropTypes from 'prop-types';
import { Box, DialogActions } from '@material-ui/core';

const DialogModalFooter = ({ children, justifyContent, ...rest }) => {
  return (
    <Box p={2} display='flex' justifyContent={justifyContent}>
      <DialogActions {...rest}>{children}</DialogActions>
    </Box>
  );
};

DialogModalFooter.propTypes = {
  justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center'])
};

DialogModalFooter.defaultProps = {
  justifyContent: 'flex-start'
};

export default DialogModalFooter;
