import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, makeStyles } from '@material-ui/core';
import { useButtonTypeColors } from './Button/utils';

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: 'white !important',
    opacity: 0.5
  }
}));

const Button = ({
  type,
  size,
  variant,
  color,
  disabled,
  startIcon,
  endIcon,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const buttonType = useButtonTypeColors(type, variant);
  return (
    <MuiButton
      {...rest}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      classes={{ disabled: classes.disabled }}
      style={buttonType}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node
};

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  disabled: false,
  size: 'medium'
};

export default Button;
