import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: '1px solid ' + theme.palette.divider
  },
  image: {
    width: '9.0625rem',
    marginRight: '1.375rem',
    cursor: 'pointer'
  },
  appName: {
    fontSize: '1.25rem',
    opacity: 0.54,
    color: theme.palette.secondary.main,
    paddingTop: theme.spacing(1) / 2
  },
  toolbar: {
    minHeight: '3.75rem'
  },
  barHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.text.primary.main
  },
  menuButton: {
    marginRight: theme.spacing(1) / 2
  }
}));

export const ApplicationBar = ({
  appName,
  logo,
  onImageClick,
  children,
  onMenuClick,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    onImageClick();
  };

  const handleMenuClick = () => {
    onMenuClick();
  };

  return (
    <AppBar
      position='fixed'
      className={classes.appBar}
      elevation={0}
      color='default'
    >
      <Toolbar className={classes.toolbar}>
        {matches && (
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!matches && (
          <img
            onClick={handleClick}
            className={classes.image}
            src={logo}
            alt={appName}
          />
        )}
        <div className={classes.barHolder}>
          <Typography className={classes.appName} variant='h4'>
            {appName}
          </Typography>
          <div {...rest}>{children}</div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

ApplicationBar.propTypes = {
  appName: PropTypes.string.isRequired,
  logo: PropTypes.any.isRequired,
  onImageClick: PropTypes.func,
  onMenuClick: PropTypes.func
};

ApplicationBar.defaultProps = {
  onImageClick: () => {},
  onMenuClick: () => {}
};

export default ApplicationBar;
