import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import theme from '../../../example/src/theme/theme';

const VARIANT_ALERT_MODAL = {
  ERROR: {
    icon: <CancelIcon style={{ color: theme.palette.error.main }} />,
    color: theme.palette.error.main
  },
  SUCCESS: {
    icon: <CheckCircleIcon style={{ color: theme.palette.success.main }} />,
    color: theme.palette.success.main
  },
  WARNING: {
    icon: <ErrorIcon style={{ color: theme.palette.warning.main }} />,
    color: theme.palette.warning.main
  },
  INFO: {
    icon: <InfoIcon style={{ color: theme.palette.info.main }} />,
    color: theme.palette.info.main
  }
};

/**
 *
 * @param type
 * @returns {{color: string, icon}}
 */
export const useVariantDetails = (type) => {
  switch (type) {
    case 'error':
      return VARIANT_ALERT_MODAL.ERROR;
    case 'success':
      return VARIANT_ALERT_MODAL.SUCCESS;
    case 'warning':
      return VARIANT_ALERT_MODAL.WARNING;
    case 'info':
    default:
      return VARIANT_ALERT_MODAL.INFO;
  }
};
