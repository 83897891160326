import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(9),
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardContentInner: {
    display: 'flex',
    paddingBottom: theme.spacing(1.3),
    justifyContent: 'space-between',
    flexFlow: 'column'
  },
  card: {
    padding: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  cardContent: {
    padding: '0 !important'
  },
  cardTitle: {
    fontSize: theme.typography.h2.fontSize
  }
}));

const SimpleCard = ({ children, title, subtitle }) => {
  const classes = useStyles();

  return (
    <Card elevation={2} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <section className={classes.cardContentInner}>
          {title && (
            <Typography className={classes.cardTitle} variant='h3'>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant='body2' color='textSecondary' display='block'>
              {subtitle}
            </Typography>
          )}
        </section>
        {children}
      </CardContent>
    </Card>
  );
};

SimpleCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

SimpleCard.defaultProps = {};

export default SimpleCard;
