import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';

import { useStyles } from './menu-item.styles';

/**
 * Creates a menu entry with nested options
 *
 * @param {string} title
 * @param {JSX.Element|Object} icon
 * @param {boolean} active
 * @param {Array|Object} children
 * @param {boolean} open
 * @param {boolean} short
 * @param {object} rest
 * @param {string} name
 * @returns {JSX.Element}
 * @constructor
 */
const SidebarNestedMenu = ({
  title,
  icon,
  active,
  children,
  open,
  short,
  name,
  ...rest
}) => {
  const hasIcon = icon !== undefined;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(open || active);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClickAway = (event) => {
    if (event.detail.name !== name) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('nestedWasClicked', onClickAway, true);
    return () =>
      document.removeEventListener('nestedWasClicked', onClickAway, true);
  });

  /**
   * Handles click on parent entry event
   * @param {Object|Event} event
   */
  const handleClick = (event) => {
    const newState = !isOpen;
    // eslint-disable-next-line no-undef
    const nestedWasClicked = new CustomEvent('nestedWasClicked', {
      detail: { name }
    });
    document.dispatchEvent(nestedWasClicked);
    if (short) {
      setAnchorEl(event.currentTarget);
    }
    setIsOpen(newState);
  };

  /**
   * Handles popup menu close action
   */
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  /***
   * Expandable icon on parent entry
   *
   * @returns {JSX.Element|null}
   */
  const expandIcon = () => {
    if (short) return null;
    return isOpen ? (
      <ExpandLess className={classes.expandIcon} />
    ) : (
      <ExpandMore className={classes.expandIcon} />
    );
  };

  /**
   * Collapsible menu
   *
   * @returns {JSX.Element}
   */
  const collapsibleMenu = () => (
    <Collapse in={isOpen} timeout='auto' unmountOnExit>
      <List component='div' disablePadding className={classes.childList}>
        {children}
      </List>
    </Collapse>
  );

  /**
   * Popup menu
   *
   * @returns {JSX.Element}
   */
  const popupMenu = () => (
    <Menu
      id='simple-menu'
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        className: classes.popoverContainer
      }}
    >
      <span className={classes.popupHolder} onClick={handleClose}>
        {children}
      </span>
    </Menu>
  );

  return (
    <React.Fragment>
      <ListItem
        button
        className={clsx({
          [classes.activeButton]: active
        })}
        onClick={handleClick}
        {...rest}
      >
        {hasIcon && (
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        )}
        <ListItemText primary={title} className={classes.title} />
        {expandIcon()}
      </ListItem>
      {short ? popupMenu() : collapsibleMenu()}
    </React.Fragment>
  );
};

SidebarNestedMenu.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  active: PropTypes.bool,
  open: PropTypes.bool,
  short: PropTypes.bool,
  name: PropTypes.string
};

SidebarNestedMenu.defaultProps = {
  active: false,
  open: false,
  short: false,
  name: 'some-name'
};

export default SidebarNestedMenu;
