import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}));

const Page = ({ breadcrumbs, children, ...rest }) => {
  const classes = useStyles();
  return (
    <>
      {breadcrumbs || null}
      <div className={classes.root} {...rest}>
        {children}
      </div>
    </>
  );
};

Page.propTypes = {
  breadcrumbs: PropTypes.element
};

export default Page;
