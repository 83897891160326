import React from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';

import SidebarMenuItem from './SidebarMenuItem';
import SidebarNestedMenu from './SidebarNestedMenu';
import SidebarMenuList from './SidebarMenuList';

const DEFAULT_ENTRY = {
  title: null,
  icon: undefined,
  path: null,
  items: null,
  exact: false,
  strict: false
};

const SidebarMenu = ({ entries, currentPath, shortSidebar, onClick }) => {
  const checkActive = ({ path, exact, strict }) => {
    return matchPath(currentPath, { path, exact, strict }) !== null;
  };

  const checkActiveItems = ({ exact, strict }, items) => {
    const path = Object.entries(items).map(([, entry]) => entry.path);

    return matchPath(currentPath, { path, exact, strict }) !== null;
  };

  const renderMenuItem = (entry, id) => {
    const { title, icon, path } = { ...DEFAULT_ENTRY, ...entry };

    const handleClick = () => {
      onClick(path);
    };

    return (
      <SidebarMenuItem
        key={id}
        title={title}
        onClick={handleClick}
        icon={icon}
        active={checkActive(entry)}
        short={shortSidebar}
      />
    );
  };

  const renderNestedMenu = (entry, id) => {
    const { title, icon, items } = { ...DEFAULT_ENTRY, ...entry };

    return (
      <SidebarNestedMenu
        key={id}
        title={title}
        icon={icon}
        active={checkActiveItems(entry, items)}
        short={shortSidebar}
        name={id}
      >
        {Object.entries(items).map(([key, entry]) => {
          return renderMenuItem(entry, 'nested-' + key);
        })}
      </SidebarNestedMenu>
    );
  };

  return (
    <SidebarMenuList>
      {Object.entries(entries).map(([key, entry]) => {
        return entry.items
          ? renderNestedMenu(entry, key)
          : renderMenuItem(entry, key);
      })}
    </SidebarMenuList>
  );
};

SidebarMenu.propTypes = {
  entries: PropTypes.object.isRequired,
  currentPath: PropTypes.string,
  shortSidebar: PropTypes.bool
};

SidebarMenu.defaultProps = {
  currentPath: '/',
  shortSidebar: false
};

export default SidebarMenu;
