import { useTheme } from '@material-ui/core';

export const getColor = (color) => {
  const theme = useTheme();

  switch (color) {
    case 'default':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    case 'success':
      return theme.palette.success.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'danger':
      return theme.palette.error.main;
  }
};
