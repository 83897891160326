import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Select, withStyles } from '@material-ui/core';
import { getSize } from './index';

const useStyles = makeStyles((theme) => ({
  fieldSizes: {
    paddingTop: ({ fieldSize }) => fieldSize,
    paddingBottom: ({ fieldSize }) => fieldSize
  }
}));

const StyledSelect = withStyles((theme) => ({
  '@global': {
    '.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover, .MuiListItem-button:hover':
      {
        backgroundColor: theme.palette.primary.superLight
      }
  },
  root: {
    width: '32em'
  },
  icon: {
    color: theme.palette.primary.main
  }
}))(Select);

const FormSelectField = ({
  children,
  dimension,
  defaultValue,
  onChange,
  ...rest
}) => {
  const fieldSize = getSize(dimension);
  const classes = useStyles({ fieldSize });

  return (
    <StyledSelect
      variant='outlined'
      value={defaultValue}
      onChange={onChange}
      classes={{ root: classes.fieldSizes }}
      {...rest}
    >
      {children}
    </StyledSelect>
  );
};

FormSelectField.propTypes = {
  dimension: PropTypes.oneOf(['small', 'medium', 'large'])
};

FormSelectField.defaultProps = {
  dimension: 'medium'
};

export default FormSelectField;
