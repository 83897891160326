import { useTheme } from '@material-ui/core/styles';

const progressColor = (variant) => {
  const theme = useTheme();

  switch (variant) {
    case 'low':
      return theme.palette.warning.main;
    case 'medium':
      return theme.palette.grey['500'];
    case 'high':
      return theme.palette.success.main;
  }
};

export default progressColor;
