import theme from '../../../example/src/theme/theme';

const BUTTON_TYPE = {
  ERROR: {
    outlined: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main
    },
    contained: {
      backgroundColor: theme.palette.error.main
    }
  },
  SUCCESS: {
    outlined: {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main
    },
    contained: {
      backgroundColor: theme.palette.success.main
    }
  },
  WARNING: {
    outlined: {
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main
    },
    contained: {
      backgroundColor: theme.palette.warning.main
    }
  },
  INFO: {
    outlined: {
      color: theme.palette.info.main,
      borderColor: theme.palette.info.main
    },
    contained: {
      backgroundColor: theme.palette.info.main
    }
  }
};

/**
 * this function returns a object with the color for each type of button
 * @param type
 * @param variant
 * @returns {{color: string, icon}}
 */
export const useButtonTypeColors = (type, variant) => {
  switch (type) {
    case 'error':
      return BUTTON_TYPE.ERROR[variant];
    case 'success':
      return BUTTON_TYPE.SUCCESS[variant];
    case 'warning':
      return BUTTON_TYPE.WARNING[variant];
    case 'info':
    default:
      return BUTTON_TYPE.INFO[variant];
  }
};
