import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
  Menu,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    height: '3.15rem',
    maxWidth: '14.875em',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      paddingRight: theme.spacing(1) / 2,
      '& .MuiSvgIcon-root': {
        fontSize: '2.1875rem'
      }
    }
  },
  smallButton: {
    width: 'auto',
    '& .MuiButton-startIcon': {
      paddingRight: 0,
      marginRight: 0
    }
  },
  name: {
    fontSize: '0.9375rem',
    fontFamily: theme.typography.h3.fontFamily,
    maxWidth: '7.25rem',
    paddingRight: theme.spacing(1) / 2
  },
  role: {
    color: theme.palette.text.disabled,
    maxWidth: '7.25rem',
    lineHeight: 1,
    paddingRight: theme.spacing(1) / 2
  },
  expandIcon: {
    color: theme.palette.primary.main
  },
  indicator: {
    maxHeight: '2.125rem',
    overflow: 'hidden',
    paddingTop: theme.spacing(1) / 2
  },
  popover: {
    maxWidth: '14.875em',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  wrapper: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomWidth: 0
  }
}));

const UserMenu = ({ name, role, picture, children, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const open = Boolean(anchorEl);

  /***
   * Expandable icon on parent entry
   *
   * @returns {JSX.Element|null}
   */
  const expandIcon = () => {
    return anchorEl ? (
      <ExpandLess className={classes.expandIcon} />
    ) : (
      <ExpandMore className={classes.expandIcon} />
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width + 1);
  }, []);

  return (
    <div className='user-menu' ref={elementRef} {...rest}>
      <Box
        boxShadow={open ? 1 : 0}
        borderRadius='borderRadius'
        className={classes.wrapper}
      >
        <Button
          variant='text'
          className={clsx(classes.root, { [classes.smallButton]: matches })}
          startIcon={picture || <AccountCircleIcon />}
          onClick={handleClick}
        >
          <Box display='flex' alignItems='center' width='100%'>
            {!matches && (
              <Box
                display='flex'
                flexDirection='column'
                flexGrow='1'
                justifyContent='flex-start'
              >
                <Typography
                  component='p'
                  variant='body2'
                  className={classes.name}
                  noWrap
                >
                  {name}
                </Typography>
                <Typography
                  component='p'
                  variant='caption'
                  className={classes.role}
                  noWrap
                >
                  {role}
                </Typography>
              </Box>
            )}
            <Box className={classes.indicator}>{expandIcon()}</Box>
          </Box>
        </Button>
        <Menu
          id='user-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 1,
            className: classes.popover,
            style: { width }
          }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClick={handleClose}
        >
          {children}
        </Menu>
      </Box>
    </div>
  );
};

UserMenu.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string,
  picture: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
};

export default UserMenu;
