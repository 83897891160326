import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Breadcrumbs, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.light,
    textDecoration: 'none',
    textTransform: 'capitalize'
  }
}));

const BreadCrumbs = ({ list }) => {
  const classes = useStyles();
  if (!list) {
    return null;
  }

  return (
    <Box p={1}>
      <Breadcrumbs aria-label='breadcrumb'>
        {list.map((crumb, id) => {
          return crumb.link ? (
            <Link key={id} className={classes.link} to={crumb.link}>
              {crumb.name}
            </Link>
          ) : (
            <Typography key={id} color='textSecondary'>
              {crumb.name}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

BreadCrumbs.propTypes = {
  list: PropTypes.array.isRequired
};

BreadCrumbs.defaultProps = {
  list: false
};

export default BreadCrumbs;
