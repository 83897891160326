import React from 'react';
import PropTypes from 'prop-types';
import { Box, DialogTitle, Typography } from '@material-ui/core';

const DialogModalHeader = ({ title }) => {
  return (
    <DialogTitle id='alert-dialog-title'>
      <Box p={1}>
        <Typography color='primary' variant='h3' gutterBottom>
          {title}
        </Typography>
      </Box>
    </DialogTitle>
  );
};

DialogModalHeader.propTypes = {
  title: PropTypes.string
};

DialogModalHeader.defaultProps = {
  title: ''
};

export default DialogModalHeader;
