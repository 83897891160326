import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { AlertDialogModalHeader } from './AlertDialog';

const AlertDialogModal = ({
  onClose,
  open,
  title,
  variant,
  children,
  ...rest
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      {...rest}
    >
      {title && <AlertDialogModalHeader variant={variant} title={title} />}
      {children}
    </Dialog>
  );
};

AlertDialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  title: PropTypes.string,
  onClose: PropTypes.func
};

AlertDialogModal.defaultProps = {
  open: false,
  variant: 'info'
};

export default AlertDialogModal;
