import React, { createContext, useContext, useEffect, useState } from "react";
import { MsalProvider } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import {
  getAllRoles,
  getAllTokens,
  retrieveCurrentTokenPerScope,
} from "./utils";

const INITIAL_VALUE = {
  authenticationFailed: false,
  isAuthenticating: true,
  isAuthenticated: false,
  isInitializing: true,
  isInitialized: false,
  account: null,
  token: null,
  roles: [],
};

const AuthContext = createContext(INITIAL_VALUE);
const LoginContext = createContext(() => {});
const LogoutContext = createContext(() => {});
const GetTokenContext = createContext(() => {});

export const useSataAuth = () => useContext(AuthContext);
export const useSataLogin = () => useContext(LoginContext);
export const useSataLogout = () => useContext(LogoutContext);
export const useSataGetToken = () => useContext(GetTokenContext);

export default function SataAuthProvider({
  instance,
  listsOfScopes,
  children,
}) {
  const accounts = instance.getAllAccounts();
  const [value, setValue] = useState(INITIAL_VALUE);

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((s) => {
        if (accounts.length > 0) {
          setAccount(instance, accounts[0]);
          instance.setActiveAccount(accounts[0]);
        } else {
          setValue({
            ...value,
            isAuthenticating: false,
            isAuthenticated: false,
            account: null,
            isInitializing: false,
            isInitialized: true,
          });
        }
      })
      .catch((a: any) => {
        console.log("err");
        console.log(a);
      });
  }, [instance]);

  // useEffect( () => {
  //   const callbackId = instance.addEventCallback((event) => {
  //     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
  //       const account = event.payload.account;
  //       getAllTokens(instance, listsOfScopes, account).then((tokens) =>
  //         getAllRoles(tokens).then((roles) => {
  //           setValue({
  //             ...value,
  //             isAuthenticating: false,
  //             isAuthenticated: true,
  //             account: { ...account, roles },
  //             isInitializing: false,
  //             isInitialized: true
  //           });
  //         }));
  //       instance.setActiveAccount(account);
  //     }
  //   })

  //   return () => {
  //     if (callbackId) {
  //       instance.removeEventCallback(callbackId);
  //     }
  //   }
  //
  // }, [instance])

  const setAccount = (instance, account) => {
    getAllTokens(instance, listsOfScopes, account).then((tokens) =>
      getAllRoles(tokens).then((roles) => {
        setValue({
          ...value,
          isAuthenticating: false,
          isAuthenticated: true,
          account: { ...accounts[0], roles },
          isInitializing: false,
          isInitialized: true,
        });
      })
    );
  };

  const login = async () => {
    // instance.handleRedirectPromise().then(async () => {
    instance.loginRedirect(listsOfScopes[0]?.split(",")).then((response) => {
      // console.log(response);
      // set active account after redirect
      // if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      //   const account = event.payload.account;
      //   getAllTokens(instance, listsOfScopes, account).then((tokens) =>
      //     getAllRoles(tokens).then((roles) => {
      //       setValue({
      //         ...value,
      //         isAuthenticating: false,
      //         isAuthenticated: true,
      //         account: { ...account, roles },
      //         isInitializing: false,
      //         isInitialized: true
      //       });
      //     }));
      //   instance.setActiveAccount(account);
      // }
    });
    // });

    //
    // const accounts = instance.getAllAccounts();
    // if (accounts.length > 0) {
    //   getAllTokens(instance, listsOfScopes, accounts[0]).then((tokens) =>
    //     getAllRoles(tokens).then((roles) => {
    //       setValue({...value, isAuthenticating: false, isAuthenticated: true, account: {...accounts[0], roles }, isInitializing: false, isInitialized: true});
    //     }));
    //   instance.setActiveAccount(accounts[0]);
    // }
    //
    //
    // // handle auth redired/do all initial setup for msal
    // instance.handleRedirectPromise().then(authResult=>{
    //   // Check if user signed ins
    //   const account = instance.getActiveAccount();
    //   if(account){
    //     getAllTokens(instance, listsOfScopes, account).then((tokens) =>
    //       getAllRoles(tokens).then((roles) => {
    //         setValue({
    //           ...value,
    //           isAuthenticating: false,
    //           isAuthenticated: true,
    //           account: { ...account, roles },
    //           isInitializing: false,
    //           isInitialized: true
    //         });
    //       }));
    //       }
    //
    //   if(!account){
    //     // redirect anonymous user to login page
    //     setValue({...value, isAuthenticating: true, isAuthenticated: false, account: null, isInitializing: false, isInitialized: true});
    //     // if(!instance.isIn)
    //     instance.loginRedirect(listsOfScopes[0]?.split(','));
    //   }
    // }).catch(err=>{
    //   // TODO: Handle errors
    //   setValue({...value, isAuthenticating: true, isAuthenticated: false, account: null, isInitializing: false, isInitialized: true, authenticationFailed: err});
    // });
  };

  const logout = async () => {
    instance.logoutRedirect();
    setValue({
      ...value,
      isAuthenticating: false,
      isAuthenticated: false,
      account: null,
      isInitializing: false,
      isInitialized: true,
      authenticationFailed: null,
    });
    sessionStorage.clear();
  };

  const getTokenPerScope = async (scope) => {
    return await retrieveCurrentTokenPerScope(instance, scope);
  };

  return (
    <MsalProvider instance={instance}>
      <AuthContext.Provider value={value}>
        <LoginContext.Provider value={login}>
          <LogoutContext.Provider value={logout}>
            {children}
          </LogoutContext.Provider>
        </LoginContext.Provider>
      </AuthContext.Provider>
    </MsalProvider>
  );
}
