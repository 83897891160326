import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { useStyles } from './menu-item.styles';

const SidebarMenuItem = ({
  title,
  icon,
  active,
  onClick,
  short,
  handleClose,
  ...rest
}) => {
  const hasIcon = icon !== undefined;
  const classes = useStyles();

  return (
    <ListItem
      button
      className={clsx({
        [classes.activeButton]: active,
        item: true,
        active: active
      })}
      onClick={onClick}
      {...rest}
    >
      {hasIcon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      <ListItemText primary={title} className={classes.title} />
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

SidebarMenuItem.defaultProps = {
  active: false,
  onClick: () => {}
};

export default SidebarMenuItem;
