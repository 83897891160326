import React from 'react';
import PropTypes from 'prop-types';
import { progressColor } from './index';
import {
  Card,
  CardContent,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(9),
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardContentInner: {
    display: 'flex',
    paddingBottom: theme.spacing(1.3),
    justifyContent: 'space-between'
  },
  card: {
    padding: theme.spacing(5),
    borderRadius: theme.shape.borderRadius
  },
  cardContent: {
    padding: '0 !important'
  },
  cardTitle: {
    fontSize: theme.typography.h4.fontSize
  },
  barColor: {
    backgroundColor: ({ color }) => color
  }
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: '0.625rem',
    borderRadius: theme.shape.borderRadius * 1.25
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: theme.shape.borderRadius * 1.25
  }
}))(LinearProgress);

const ProgressCard = ({ title, progressValue, maxValue, variant }) => {
  const progress =
    progressValue <= maxValue
      ? Math.round((progressValue / maxValue) * 100)
      : 100;
  const color = progressColor(variant);
  const classes = useStyles({ color });

  return (
    <Card elevation={2} className={classes.card}>
      <CardContent className={classes.cardContent}>
        {title && (
          <section className={classes.cardContentInner}>
            <Typography className={classes.cardTitle} variant='h3'>
              {title}
            </Typography>
            {progressValue && (
              <Typography>
                {progressValue} / {maxValue}
              </Typography>
            )}
          </section>
        )}
        {progressValue && (
          <BorderLinearProgress
            variant='determinate'
            value={progress}
            classes={{ bar: classes.barColor }}
          />
        )}
      </CardContent>
    </Card>
  );
};

ProgressCard.propTypes = {
  title: PropTypes.string,
  progressValue: PropTypes.number,
  maxValue: PropTypes.number,
  variant: PropTypes.string
};

ProgressCard.defaultProps = {
  maxValue: 100,
  variant: 'medium'
};

export default ProgressCard;
