import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.secondary.contrastText,
    minWidth: 'unset',
    width: '1.5rem',
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    paddingTop: theme.spacing(1) / 2,
    whiteSpace: 'pre'
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.light,
    borderLeftWidth: '0.250rem',
    borderStyle: 'solid',
    borderLeftColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(1) * 1.5,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  },
  activeShortButton: {
    color: theme.palette.primary.main
  },
  popupHolder: {
    '& .item': {
      backgroundColor: 'transparent',
      borderLeftWidth: 0,
      '&:hover': {
        backgroundColor: theme.palette.divider
      },
      '&.active': {
        color: theme.palette.primary.main
      }
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  childList: {
    backgroundColor: theme.palette.secondary.dark,
    '& .MuiListItem-button': {
      padding: 0,
      paddingLeft: '3.5rem',
      '& .MuiTypography-body1': {
        fontSize: theme.typography.h6.fontSize
      },
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    '& .item': {
      backgroundColor: theme.palette.secondary.dark,
      borderLeftWidth: 0,
      '&.active': {
        color: theme.palette.primary.main
      }
    }
  },
  expandIcon: {
    color: theme.palette.primary.main
  },
  popoverContainer: {
    marginTop: '-1.25rem'
  }
}));
